import './css/about.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
export default function About() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);
    return (
        <>
            <img src='/images/about_img.jpg' alt='Travaux de construction publics' className='img_home_about' />

            <div className='section_about'>
                <div className='sous_section_qui'>
                    <h2 className='titre_qui'>
                        <img src='./images/qui.png' className='img_qui' alt='Logo de l’entreprise' />
                        {t('Who Are We?')}
                    </h2>

                    <p className='p_qui'>
                        {t("At MOUVE UP, a Moroccan limited liability company, we are committed to excelling in the industrial and civil sectors. Our strategy is based on innovation, creativity, and adapting to the specific needs of our clients. With our expertise, we work across various fields, from constructing complex infrastructures to project management, urban planning, and the rehabilitation of historic buildings. Our approach is grounded in attentive listening to our clients' expectations and anticipating their needs, ensuring tailor-made solutions. The synergy of our teams, driven by a shared desire to create and provide sustainable and effective solutions, is our greatest strength. With unwavering commitment from our leadership, we have established continuous improvement as a key driver of our growth. MOUVE UP is not just a construction company: we are a trusted partner, dedicated to the success of every project we undertake."
                        )}

                    </p>

                    <div className='div_cont'>
                        <div>
                            <h2 className='titre_qui'>{t("MOUVE UP's Mission")} </h2>
                            <p className='p_qui'>
                                {t("At MOUVE UP, our mission is to meet the needs of our clients by offering innovative construction and public works solutions tailored to each specific project. We are committed to ensuring exceptional quality at every stage of our work, with rigorous monitoring and flawless finishes. Client satisfaction is at the heart of our approach, driving us to meet deadlines while controlling costs. Moreover, our commitment to health, safety, and the environment (HSE) is paramount. We implement preventive practices to ensure safe and sustainable working conditions. By collaborating with a team of passionate professionals and using modern equipment, MOUVE UP strives to continuously improve its processes to deliver services that meet our clients' expectations, solidifying our position as a trusted partner in the construction industry.")}
                            </p>
                        </div>

                        <img src='./images/about.jpg' style={{ borderRadius: '20px' }}
                            className='imag_about_qui' alt='Entreprise de Construction' />
                    </div>
                    <div className='section_travaux'>
                  
                        <h2 className='titre_qui'>{t("Our Area of Activity")}</h2>
                        <div className='list_travaux'>
                            <div className='travail'>
                                <h3>{t("Demolition Works")}</h3>
                                <p>{t("Demolition controlled and secured of buildings and structures")}</p>
                                <ul>
                                    <li>{t("Secured demolition")}</li>
                                    <li>{t("Common and specialized earthworks")}</li>
                                    <li>{t("Drilling and probing")}</li>
                                </ul>
                            </div>

                            <div className='travail'>
                                <h3>{t("Installation and Equipment")}</h3>
                                <p>{t("Installation of essential systems for a complete building")}</p>
                                <ul>
                                    <li>{t("Electricity")}</li>
                                    <li>{t("Plumbing")}</li>
                                    <li>{t("Heating")}</li>
                                    <li>{t("Air conditioning")}</li>
                                    <li>{t("Insulation")}</li>
                                </ul>
                            </div>

                            <div className='travail'>
                                <h3>{t("Finishes and Arrangements")}</h3>
                                <p>{t("Completion of interior and exterior finishes and arrangements")}</p>
                                <ul>
                                    <li>{t("Plasterwork")}</li>
                                    <li>{t("Carpentry")}</li>
                                    <li>{t("Floor coverings")}</li>
                                    <li>{t("Painting")}</li>
                                    <li>{t("Glazing")}</li>
                                    <li>{t("Various finishes")}</li>
                                </ul>
                            </div>

                            <div className='travail'>
                                <h3>{t("Structures and Major Works")}</h3>
                                <p>{t("Construction of structures and major works of buildings")}</p>
                                <ul>
                                    <li>{t("Framework")}</li>
                                    <li>{t("Roofing")}</li>
                                    <li>{t("Metal structures")}</li>
                                    <li>{t("Masonry")}</li>
                                    <li>{t("Specialized construction")}</li>
                                </ul>
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
