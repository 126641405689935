
import React from 'react';
import './css/servicesHome.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
const Services_home = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);
  const cardData = [
    {
      id: 2,
      imageSrc: './images/RestaurationBTH.jpg',
      title: t('service home 1 titre'),
      description: t("service home 1 description"),
    },
    {
      id: 3,
      imageSrc: './images/Resine.png',
      title: t('service home 2 titre'),
      description: t("service home 2 description"),
    },
    {
      id: 4,
      imageSrc: './images/isolation.jpg',
      title: t('service home 3 titre'),
      description: t('service home 3 description'),
    },
    {
      id: 1,
      imageSrc: './images/img5.jpg',
      title: t('service home 4 titre'),
      description: t('service home 4 description'),
    },
  ];

  return (
    <div className="card-grid">
      <div className="card-container">
        {cardData.map((card, index) => (
          <div className="card" key={index}>
            <img src={card.imageSrc} alt={card.title} className="card-image" />
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
            <Link className='card-lli' to={`/servicesDetail/${card.id}`}>
              <span className="card-link"> {t('View more')} +</span>
            </Link>

          </div>
        ))}
      </div>
      <Link to="/services" className="view-more-link"><button className="view-more-btn">{t('View more')} </button></Link>
    </div>
  );
};

export default Services_home;
