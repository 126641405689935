import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/contact.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

function Contact() {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [popupMessage, setPopupMessage] = useState('');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    const sendEmail = async () => {
        // Validation des champs
        if (!name || !phone || !email || !message) {
            setPopupMessage(t('All fields must be filled out!'));
            setIsPopupVisible(true);
            return;
        }

        // Validation de l'email
        if (!validateEmail(email)) {
            setPopupMessage(t('Please enter a valid email address.'));
            setIsPopupVisible(true);
            return;
        }

        // Empêcher l'envoi multiple
        if (isSending) return;
        setIsSending(true);

        try {
            const response = await axios.post(
                'https://move-up-ing.ma/mailer/send_email.php',
                {
                    name,
                    phone,
                    email,
                    message,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                setPopupMessage(t('Your message was successfully sent!'));
                setName('');
                setPhone('');
                setEmail('');
                setMessage('');
                console.log('Successfully sent email with data:', { name, phone, email, message });
            } else {
                setPopupMessage(t('An error occurred. Please try again later.'));
            }
        } catch (error) {
            setPopupMessage(t('An error occurred: ') + error.message);
        }

        setIsPopupVisible(true);
        setIsSending(false);
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className="contact-container">
            <div className="map-section">
                {/* <iframe
                    title="Location Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.890626392775!2d2.2944813160718515!3d48.85837007928792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66fddf57d5f9f%3A0x8f4db0975a8d4d8c!2sEiffel%20Tower!5e0!3m2!1sen!2sus!4v1615998377744!5m2!1sen!2sus"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                ></iframe> */}
            </div>

            <div className="form-info-section">
                <div className="contact-form">

                    <h2 className='label-contact'>{t("Name")}</h2>
                    <input
                        type="text"
                        placeholder={t("Enter your name")}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <h2>{t("Phone")}</h2>
                    <input
                        type="text"
                        placeholder={t("Enter your phone number")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <h2>{t("Email")}</h2>
                    <input
                        type="email"
                        placeholder={t("Enter your email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <h2>{t("Message")}</h2>
                    <textarea
                        placeholder={t("Enter your message")}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                    <div className='row'>
                        <button className="submit-btn col-md-3 offset-md-8 " onClick={sendEmail} disabled={isSending}>
                            {isSending ? t("Sending...") : t("Send")}
                        </button>
                    </div>
                </div>

                <div className="contact-info">
                    <h2 className="contact_titre">{t("Contact Us")}</h2>
                    <p>
                        {t("contactUS")}
                    </p>
                    <div className="info-item">
                        {/* <i className="fas fa-map-marker-alt"></i>
                        <p>{t("Address placeholder text")}</p> */}
                    </div>
                    <div className="info-item">
                        <i className="fas fa-phone-alt"></i>
                        <p>+212 5 37629404</p>
                    </div>
                    <div className="info-item">
                        <i className="fas fa-phone-alt"></i>
                        <p>+212 6 61184902</p>
                    </div>
                    <div className="info-item">
                     <i className="fas fa-envelope"></i>
                     <p>
  <a href="mailto:contact@move-up-ing.ma" style={{ textDecoration: 'none', color: 'inherit' }}>
    contact@move-up-ing.ma
  </a>
</p>
                    </div>

                    {/* <div className="social-icon  info-item">
                        <a href="/"><i className="fab fa-facebook"></i></a>
                        <a href="/"><i className="fab fa-twitter"></i></a>
                        <a href="/"><i className="fab fa-instagram"></i></a>
                        <a href="/"><i className="fab fa-youtube"></i></a>
                    </div> */}
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <p>{popupMessage}</p>
                        <button onClick={closePopup}>{t("Close")}</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Contact;
