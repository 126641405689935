import React  from 'react';
import { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageCarousel from './homeslider.js';
import { useTranslation } from 'react-i18next';
import './css/home.css';
import References from './references.js';
import WWhyChooseUs from './WhyChooseUs.js';
import YouTubeChannel from './YouTubeChannel.js';
import Footer from './footer.js';
import Services_home from './servicesHome.js';

export default function Home() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    return (

        <div>
            <ImageCarousel />
            <div className='titre_chap'>
            <img src="./images/chap.png" alt="Image" className="iconChap"  />
            <h1 className='title_service_home'>{t('Services')}</h1>
            </div>
            <div>
            <Services_home />
            </div>
            <div>
                <img src='/images/img8.jpg' alt='Travaux de construction publics ' className='img_home_reference'/>
                 <WWhyChooseUs/>
            </div>
            <br/>
            {/* <div>
            <References/>
            </div>
            <div>
            <img src='/images/img4.jpg' alt='Travaux de construction publics ' className='img_home_reference'/>
                <YouTubeChannel/>
            </div> */}
            
            
        </div>

    )
}
