import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'; 
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import './css/header.css';

export default function Header() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('selectedLanguage', lng);
        console.log("Language changed to:", lng);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage) {
            i18n.changeLanguage(savedLanguage);
        }
    }, [i18n]);

    return (
        <Navbar expand="lg" className="custom-navbar">
            <Container>
                <Navbar.Brand as={Link} to="/"><img src='./images/MOUVE_UP1.png' className='logo_mouveup' alt='MOUVEUP' /></Navbar.Brand> 
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} className='header-link' to="/">{t('Home')}</Nav.Link>      
                        <Nav.Link as={Link} className='header-link' to="/services">{t('Services')}</Nav.Link> 
                        <Nav.Link as={Link} className='header-link' to="/about">{t('About')}</Nav.Link>     
                        {/* <Nav.Link as={Link} className='header-link' to="/project">{t('Project')}</Nav.Link>   */}
                        <Nav.Link as={Link} className='header-link' to="/contact">{t("Contact Us")}</Nav.Link>  
                    </Nav>
                    <Form.Select 
                        className="selectpicker form-select w-auto ms-auto" 
                        onChange={(e) => changeLanguage(e.target.value)} 
                        value={i18n.language}
                        aria-label="Select Language"
                    >
                        <option value="en">En</option>
                        <option value="fr">Fr</option>
                    </Form.Select>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
