import React from 'react';
import './css/WhyChooseUs.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const WhyChooseUs = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
      const savedLanguage = localStorage.getItem('selectedLanguage');
      if (savedLanguage) {
          i18n.changeLanguage(savedLanguage);
      }
  }, [i18n]);
  const reasons = [
    { imgSrc: './images/savoirefaire.jpg', title: t('title why 1'), description: t('description why 1')},
    { imgSrc: './images/HSE2.png', title: t('title why 2'), description: t('description why 2')},
    { imgSrc: './images/quality2.png', title: t('title why 3'), description: t('description why 3')},
    { imgSrc: './images/dure.png', title: t('title why 4'), description: t('description why 4') },
  ];

  return (
    <div className="why-choose-us"> 
      <h2 className="section-title">{t("Why choose us")}</h2>
      <div className="reasons-grid">
        {reasons.map((reason, index) => (
          <div key={index} className="reason-card">
            <img src={reason.imgSrc} alt={reason.title} className="reason-image" />
            <h3 className="reason-title">{reason.title}</h3>
            <p className="reason-description">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
